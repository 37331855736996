.uploader-input {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  padding: 31px 10px !important;
}
label {
  width: 350px;
  box-sizing: border-box;
}
