body {
  background-color: rgb(255, 255, 255) !important;
  margin: 0;
}

div#data-table {
  /* background-color: #c2e; */
  width: 100%;
  height: 500px;
  overflow: auto;
  margin-bottom: 30;
}

.custom-mui-table-container {
  /* background-color: #ff0 !important; */
  height: 65% !important;
}

/* .vendor-table-container {
  background-color: #f0f;
} */

.vendor-table {
  /* background-color: #0f0; */
  height: 83%;
  position: absolute;
  bottom: 31px;
}

@media only screen and (min-width: 1440px) {
  .custom-mui-table-container {
    /* background-color: #f0f !important; */
    height: 62% !important;
  }
}

@media only screen and (min-width: 1920px) {
  .custom-mui-table-container {
    /* background-color: #8f8 !important; */
    height: 70% !important;
  }
}

@media only screen and (min-width: 2560px) {
  .custom-mui-table-container {
    /* background-color: #00f !important; */
    height: 75% !important;
  }

  .vendor-table {
    height: 90%;
  }
}

@media only screen and (min-width: 1024px) {
  div#data-table {
    /* background-color: #f0f; */
    height: 225px !important;
    overflow: auto !important;
  }
}

@media only screen and (min-width: 608px) {
  div#data-table {
    /* background-color: #f22; */
    height: 405px !important;
    overflow: auto !important;
  }
}

@media only screen and (min-width: 1440px) {
  div#data-table {
    /* background-color: rgb(0, 150, 50); */
    height: 475px !important;
    overflow: auto !important;
  }
}

@media only screen and (min-width: 1920px) {
  div#data-table {
    /* background-color: rgb(251, 255, 0); */
    height: 850px !important;
    overflow: auto !important;
  }
}

.custom-text-input {
  color: #222;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  width: 80%;
  text-overflow: ellipsis;
}

.custom-text-input:focus {
  outline: none !important;
}

p.filter-menu-label {
  color: #444;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: uppercase;
  padding: 0px 10px;
}

.register-form-cover {
  height: calc(100vh - 45px);
  width: 100%;
}

.d-flex {
  display: flex !important;
  align-items: center !important;
}

.justify-between {
  justify-content: space-between;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
/* * {
  scrollbar-width: auto;
  scrollbar-color: #c9c9c9 #ffffff;
} */

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 12px;
} */

/* *::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c9c9c9;
  border-radius: 5px;
  border: 0px solid #ffffff;
} */

.mr {
  margin-right: 5px;
}

.swal2-container {
  z-index: 9999 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn-wait {
  background-color: #fff;
  border: 1px solid #dc3535;
  border-radius: 5px;
  color: #dc3535;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 4px 10px;
}

.btn-open {
  background-color: transparent;
  color: #5f9df7;
  border: none;
  margin-bottom: 5px;
}

select {
  /* Reset Select */
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  background-image: none;
  cursor: pointer;
  border: 1px solid #ccc;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  height: 3em;
  border-radius: 0.25em;
  overflow: hidden;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: 0.25s all ease;
  pointer-events: none;
}
/* Transition */
.select:hover::after {
  color: #f39c12;
}

option {
  padding: 10px !important;
}

.input-label {
  margin: 0;
  font-size: 13px;
}
